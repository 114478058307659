import React from 'react'
import Menu from '../Menu/Menu';
import SideMenu from "../SideMenu/SideMenu.js";
import Page from "../Page/Page";
import UserNameLoader from "../UserName/UserName";
import './Layout.scss';

const Layout = ({activeMenu, isLoggedIn, noMenu, children, layoutClass, width}) => {
    const userName = <UserNameLoader/>;
    let menu = <Menu userName={userName}/>;
    let sideMenu = <SideMenu activeMenu={activeMenu}/>;
    let flex = "d-flex";

    if (!isLoggedIn) {
        flex =""
        sideMenu = "";
        menu = <Menu userName=""/>;
    }

    if (noMenu) {
        menu = "";
        sideMenu = "";
    }

    return <>
        {menu}
        <div className={flex}>
            {sideMenu}
            <Page layoutClass={layoutClass} width={width}>{children}</Page>
        </div>
    </>
}

export default Layout;