import React from 'react';
import {Spinner} from "react-bootstrap";
import './SkipIfNotAdminUser.scss';
import {GetUserRoles} from "../GetUserRoles";

class SkipIfNotAdminUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            roles: null,
        }
    }

    async componentDidMount() {
        this.setState({roles: await new GetUserRoles()});
    }

    render() {
        if (!this.state.roles) {
            return <>
                <Spinner animation="border" variant="primary"/> Loading...
                <br/><br/>
            </>
        }
        if (this.state.roles && this.state.roles.editUsers === false) {

            return <i className="regular-user-text">This account does not have access to user management</i>
        }
        return this.props.children;
    }
}

export default SkipIfNotAdminUser;