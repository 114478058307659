import React from 'react';
import {get, put} from '../../utils/request';
import Alert from "../../component/Alert/Alert";
import Button from "../../component/Button/Button";
import Container from "../../component/Container/Container";
import CountrySelector from "../../component/CountrySelector";
import {CountryByCode} from "../../component/CountryByCode";
import {Col, Form, Row, Spinner} from 'react-bootstrap';
import Layout from "../../component/Layout/Layout";
import './settings.scss';

import {faSave} from "@fortawesome/free-solid-svg-icons";

class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alertClass: props.alertClass,
            billingEmailError: false,
            data: {},
            errorMessage: '',
            errors: {},
            history: props.history,
            hrefLink: props.hrefLink,
            lastSubmitTime: null,
            loadingSuccess: false,
            successMessage: '',
            variant: props.variant,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleCountryCodeChange = this.handleCountryCodeChange.bind(this);
        this.hasError = this.hasError.bind(this);
        this.loadData = this.loadData.bind(this);
        this.showError = this.showError.bind(this);
        this.showBillingEmailError = this.showBillingEmailError.bind(this);
        this.validateBillingEmail = this.validateBillingEmail.bind(this);
    }

    async loadData() {
        const [res, err] = await get(this, "/partner/auth/settings");
        if (err) {
            this.setState({errorMessage: "Error: " + err});
            this.setState({errors: {}})
            return
        }
        this.setState({data: res.data});
        this.setState({loadingSuccess: true});
    }

    async componentDidMount() {
        await this.loadData();
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const data = this.state.data;
        data[name] = value;

        this.setState({data: data});
    }

    handleCountryCodeChange(newValue) {
        const data = this.state.data;
        data.countryCode = newValue;
        this.setState({data: data});
    }

    hasError(field) {
        if (this.state.errors[field]) {
            return true;
        }
        return false;
    }

    showError(field) {
        const error = this.state.errors[field];
        if (error) {
            this.errorTranslator(error, field)
            return error.message;
        }
        return;
    }

    errorTranslator(error, field) {
        if (error.key === "length") {
            error.message = "Please enter a " + field.toLowerCase();
        }
    }

    validateBillingEmail(billingEmail) {
        let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');

        return regex.test(billingEmail);
    }

    checkErrorsSubmit(value) {
        if(!this.validateBillingEmail(value)) {
            this.setState({billingEmailError: true})
            return true;
        }
    }

    showBillingEmailError(value) {
        if (value) {
            return "Please enter your billing email in format: yourname@example.com";
        }
        return "";
    }

    async handleSubmit(event) {
        if (event) { // TODO - remove if possible
            event.preventDefault();
        }
        this.setState({successMessage: ""});
        this.setState({errorMessage: ""});

        if(this.state.data.emailForBilling) {
            const error = this.checkErrorsSubmit(this.state.data.emailForBilling);
            if (error) {
                this.setState({billingEmailError: true})

                return;
            }
            this.setState({billingEmailError: false})
        }

        const [res, err] = await put(this, "/partner/auth/settings", this.state.data);
        if (err) {
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 422:
                        const errors = err.response.data.ValidationErrors;
                        this.setState({errors: errors});
                        return;
                    case 400:
                        this.setState({errorMessage: "" + err});
                        return;
                }
            }
            this.setState({errorMessage: "Error: " + err});
            return;
        }

        this.setState({successMessage: "Settings saved"});
        this.setState({errors: ""});

        window.scrollTo({top: 0, behavior: 'smooth'});

        if(this.state.hrefLink) {
            this.props.history.push(this.state.hrefLink);
        }
    }

    render() {
        const { billingEmailError, data, errorMessage, loading, loadingSuccess, successMessage, variant } = this.state;

        return <Layout activeMenu="/settings" isLoggedIn={true}>
            <h1 className="main-heading">Settings</h1>
            {loading &&
                <>
                    <Spinner animation="border" variant="primary"/> Loading data...
                    <br/><br/>
                </>
            }
            {successMessage && <Alert variant="success">{successMessage}</Alert>}
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            {loadingSuccess &&
                <>
                    <Form onSubmit={e => this.handleSubmit(e)}>
                        <Container variant={variant}>
                            <h2>Billing details</h2>
                            <Form.Group as={Row} controlId="formPlaintextName">
                                <Form.Label column sm="2">Name*</Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        defaultValue={data.name}
                                        isInvalid={this.hasError("name")}
                                        onChange={this.handleChange}
                                    />
                                    <Form.Control.Feedback className="form-validation-error" type="invalid">{this.showError("name")}</Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formEmailForBilling">
                                <Form.Label column sm="2">Email for billing</Form.Label>
                                <Col sm="10">
                                    <Form.Control       isInvalid={billingEmailError}
                                                        type="email"
                                                        name="emailForBilling"
                                                        onChange={this.handleChange}
                                                        value={data.emailForBilling}
                                    />
                                    <Form.Control.Feedback
                                        type="invalid"
                                        className="invalid-feedback">{this.showBillingEmailError(billingEmailError)}</Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formCountryCode">
                                <Form.Label column sm="2">Country*</Form.Label>
                                <Col>
                                    <CountrySelector
                                        defaultValue={{
                                            value: data.countryCode,
                                            label: CountryByCode(data.countryCode)
                                        }}
                                        onChange={this.handleCountryCodeChange.bind(this)}
                                        hasError={this.hasError("countryCode")}
                                    />
                                    {this.hasError("countryCode") &&
                                        <div className="invalid-feedback block-display form-validation-error">Please select a country</div>
                                    }
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formAddress">
                                <Form.Label column sm="2">Address</Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        type="text"
                                        name="address"
                                        defaultValue={data.address}
                                        isInvalid={this.hasError("address")}
                                        onChange={this.handleChange}
                                    />
                                    <Form.Control.Feedback
                                        type="invalid">{this.showError("address")}</Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formRegistrationNumber">
                                <Form.Label column sm="2">Registration number</Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        type="text"
                                        name="registrationNumber"
                                        defaultValue={data.registrationNumber}
                                        isInvalid={this.hasError("registrationNumber")}
                                        onChange={this.handleChange}
                                    />
                                    <Form.Control.Feedback
                                        type="invalid">{this.showError("registrationNumber")}</Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formVatNumber">
                                <Form.Label column sm="2">VAT number</Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        type="text"
                                        name="vatNumber"
                                        defaultValue={data.vatNumber}
                                        isInvalid={this.hasError("vatNumber")}
                                        onChange={this.handleChange}
                                    />
                                    <Form.Control.Feedback
                                        type="invalid">{this.showError("vatNumber")}</Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                        </Container>
                        <div className="d-flex">
                            <Button label="Save settings" faIcon={faSave} className="primary btn btn-right" type="submit"/>
                        </div>
                    </Form>
                </>
            }
        </Layout>;
    }
}

export default Settings