import React from 'react';
import {post} from '../../utils/request';
import {Col, Form, Row} from 'react-bootstrap';
import Alert from "../../component/Alert/Alert";
import Button from "../../component/Button/Button";
import Container from "../../component/Container/Container";
import Layout from "../../component/Layout/Layout";
import Link from "../../component/Link/Link";
import {faArrowCircleLeft, faUser} from "@fortawesome/free-solid-svg-icons";
import SkipIfNotAdminUser from "../../component/SkipIfNotAdminUser/SkipIfNotAdminUser";

class UserAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                name: '',
                email: '',
                rolesEditUsers: false
            },
            errors: {},
            error: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.hasError = this.hasError.bind(this)
        this.showError = this.showError.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const data = this.state.data;

        if (target.type === 'checkbox') {
            data[name] = event.target.checked;
        } else {
            data[name] = value;
        }

        this.setState({data: data});
    }

    hasError(field) {
        if (this.state.errors[field]) {
            return true
        }
        return false
    }

    showError(field) {
        const error = this.state.errors[field]
        if (error) {
            this.errorTranslator(error, field)
            return error.message;
        }
        return
    }

    errorTranslator(error, field) {
        if (error.key === "length") {
            switch (field) {
                case "name":
                    error.message = "Please enter a " + field.toLowerCase();
                    break;
            }

        } else if (error.key === "email") {
            error.message = "Please enter your " + field.toLowerCase() + " in format: yourname@example.com";
        }
    }

    async handleSubmit(event) {
        event.preventDefault();

        const [res, err] = await post(this, "/partner/auth/user", this.state.data);

        if (err) {
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 409:
                        this.setState({error: "Email already exists."});
                        return;
                    case 422:
                        const errors = err.response.data.ValidationErrors
                        this.setState({errors: errors})
                        return;
                    case 500:
                        this.setState({error: "Internal error."});
                        return;
                }
            }
            this.setState({error: "error: " + err});

            return;
        }
        this.props.history.push('/users')
    }

    render() {
        const { data, error } = this.state;

        return <Layout activeMenu="/users" isLoggedIn={true}>
            <SkipIfNotAdminUser>
                <h1 className="main-heading">Add a new user</h1>
                {error && <Alert variant="danger">{error}</Alert>}
                <Container>
                    <Form onSubmit={e => this.handleSubmit(e)}>
                        <h2>User details</h2>
                        <Form.Group as={Row} controlId="formName">
                            <Form.Label column sm="2">Name</Form.Label>
                            <Col sm="10">
                                <Form.Control   isInvalid={this.hasError("name")}
                                                type="text"
                                                name="name"
                                                onChange={this.handleChange}
                                                value={data.name}
                                />
                                <Form.Control.Feedback
                                    type="invalid"
                                    className="invalid-feedback">{this.showError("name")}</Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formEmail">
                            <Form.Label column sm="2">Email</Form.Label>
                            <Col sm="10">
                                <Form.Control       isInvalid={this.hasError("email")}
                                                    type="text"
                                                    name="email"
                                                    onChange={this.handleChange}
                                                    value={data.email}
                                />
                                <Form.Control.Feedback
                                    type="invalid"
                                    className="invalid-feedback">{this.showError("email")}</Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formUserRoles">
                            <Form.Label column sm="2">Admin user</Form.Label>
                            <Col sm="3">
                                <Form.Check type="checkbox" name="rolesEditUsers" defaultChecked={data.rolesEditUsers}
                                            onChange={this.handleChange}/>
                            </Col>
                        </Form.Group>
                    </Form>
                </Container>
                <div className="d-flex justify-content-end">
                    <Link className="primary-outline btn btn-md mr3" faIcon={faArrowCircleLeft} href={"/users"} label="Back"/>
                    <Button className="btn primary" faIcon={faUser} label="Add user" onClick={this.handleSubmit}/>
                </div>
            </SkipIfNotAdminUser>
        </Layout>;
    }
}

export default UserAdd