import React from 'react';
import {get, put} from '../../utils/request';
import Alert from "../../component/Alert/Alert";
import Button from "../../component/Button/Button";
import Container from "../../component/Container/Container";
import Layout from "../../component/Layout/Layout";
import {Col, Form, Row, Spinner} from "react-bootstrap";
import {faArrowCircleLeft, faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import Link from "../../component/Link/Link";
import SkipIfNotAdminUser from "../../component/SkipIfNotAdminUser/SkipIfNotAdminUser";

const statuses = [
    {label: "", value: ""},
    {label: "Active", value: "active"},
    {label: "Waiting Confirmation", value: "waiting_confirm"},
    {label: "Disabled", value: "disabled"},
];

class UserEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                name: '',
                email: '',
                status: '',
                rolesEditUsers: false,
            },
            errorMessage: '',
            errors: {},
            loading: true,
            loadingSuccess: false,
            id: this.props.match.params.id,
            successMessage: '',
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.hasError = this.hasError.bind(this);
        this.loadData = this.loadData.bind(this);
        this.showError = this.showError.bind(this);
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData() {
        const [res, err] = await get(this, "/partner/auth/user/" + this.state.id);
        if (err) {
            if (err && err.response && err.response.status) {
                switch (err.response.status) {
                    case 400:
                        this.setState({errorMessage: "Bad request, invalid partner user ID sent."});

                        return;
                    case 404:
                        this.setState({errorMessage: "User not found."});

                        return;
                    case 500:
                        this.setState({errorMessage: "Internal error: " + err.response.message});

                        return;
                }
            }
            this.setState({errorMessage: err});
            this.setState({loading: false});
        }

        this.setState({data: res.data});
        this.setState({loading: false});
        this.setState({loadingSuccess: true});
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const data = this.state.data;

        if (target.type === 'checkbox') {
            data[name] = event.target.checked;
        } else {
            data[name] = value;
        }

        this.setState({data: data});
    }

    handleStatusChange(event) {
        const data = this.state.data;
        data.status = event.value;
        this.setState({data: data});
    }

    hasError(field) {
        if (this.state.errors[field]) {
            return true;
        }
        return false;
    }

    showError(field) {
        const error = this.state.errors[field];
        if (error) {
            this.errorTranslator(error, field)
            return error.message;
        }
        return;
    }

    errorTranslator(error, field) {
        if (error.key === "length") {
            switch (field) {
                case "name":
                    error.message = "Please enter a name";
                    break;
            }
        }
    }

    capitalize(string) {
        return string ? string[0].toUpperCase() + string.slice(1) : "";
    }

    async handleSubmit(event) {
        event.preventDefault();

        this.setState({errorMessage: ""});
        this.setState({successMessage: ""});

        const [res, err] = await put(this, "/partner/auth/user/" + this.state.id, this.state.data);
        if (err) {
            if (err && err.response && err.response.status) {
                switch (err.response.status) {
                    case 400:
                        this.setState({errorMessage: "Bad request: " + err.response.message});

                        return;
                    case 412:
                        this.setState({errorMessage: "Validation error: " + JSON.stringify(err.response.data.ValidationErrors)});

                        return;
                    case 422:
                        const errors = err.response.data.ValidationErrors;
                        this.setState({errors: errors});

                        return;
                    case 500:
                        this.setState({errorMessage: "Internal error: " + err.response.message});

                        return;
                }
            }
            this.setState({errorMessage: err});

            return;
        }
        this.setState({successMessage: "User information saved"});
        this.setState({errors: ""});
        this.props.history.push('/users')
    }

    render() {
        const { data, errorMessage, loading, loadingSuccess, successMessage } = this.state;

        return <Layout activeMenu="/users" isLoggedIn={true}>
            <SkipIfNotAdminUser>
                <h1>Edit user</h1>
                {loading &&
                    <>
                        <Spinner animation="border" variant="primary"/>Loading user information...
                        <br/><br/>
                    </>
                }
                {successMessage && <Alert variant="success">{successMessage}</Alert>}
                {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                {loadingSuccess &&
                    <>
                        <Container>
                            <Form onSubmit={e => this.handleSubmit(e)}>
                                <h2>User details</h2>
                                <Form.Group as={Row} controlId="formName">
                                    <Form.Label column sm="2">Name</Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            type="text"
                                            name="name"
                                            defaultValue={data.name}
                                            isInvalid={this.hasError("name")}
                                            onChange={this.handleChange}
                                        />
                                        <Form.Control.Feedback
                                            type="invalid">{this.showError("name")}</Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formEmail">
                                    <Form.Label column sm="2">Email</Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            type="text"
                                            name="email"
                                            defaultValue={data.email}
                                            disabled
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formStatus">
                                    <Form.Label column sm="2">Status</Form.Label>
                                    <Col sm="10">
                                        <Select
                                            name="status"
                                            options={statuses}
                                            defaultValue={{
                                                value: data.status,
                                                label: this.capitalize(data.status)
                                            }}
                                            onChange={this.handleStatusChange}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formUserRoles">
                                    <Form.Label column sm="2">Can edit users</Form.Label>
                                    <Col sm="3">
                                        <Form.Check type="checkbox" name="rolesEditUsers" defaultChecked={data.rolesEditUsers}
                                                    onChange={this.handleChange}/>
                                    </Col>
                                </Form.Group>
                            </Form>
                        </Container>
                        <div className="d-flex justify-content-end">
                            <Link className="primary-outline btn btn-md mr3" faIcon={faArrowCircleLeft} href={"/users"} label="Back"/>
                            <Button label="Save" faIcon={faCheckCircle} className="primary btn btn-md" onClick={this.handleSubmit}/>
                        </div>
                    </>
            }
            </SkipIfNotAdminUser>
        </Layout>;
    }
}

export default UserEdit