import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import NotFound from './page/notFound/NotFound';
import Activate from "./page/activate/Activate";
import ActivateDone from "./page/activate/ActivateDone";
import Confirm from './page/confirm/Confirm';
import ConfirmDone from './page/confirm/ConfirmDone';
import PasswordDone from "./page/password/PasswordDone";
import PasswordExpired from "./page/password/PasswordExpired";
import PasswordForgot from "./page/password/PasswordForgot";
import PasswordReset from "./page/password/PasswordReset";
import PasswordSent from "./page/password/PasswordSent";
import Settings from "./page/settings/Settings";
import SignIn from './page/signIn/SignIn';
import SignOut from './page/signOut/SignOut';
import Users from './page/users/Users';
import UserAdd from './page/users/UserAdd';
import UserEdit from './page/users/UserEdit';
import Home from "./page/home/Home";
import InvoiceList from "./page/billing/InvoiceList";
import Accounts from "./page/accounts/Accounts";
import NewAccount from "./page/accounts/NewAccount";
import ViewAccount from "./page/accounts/ViewAccount";
import EditAccount from "./page/accounts/EditAccount";
import * as serviceWorker from './serviceWorker';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-138891054-3');
ReactGA.pageview(window.location.pathname + window.location.search);


const routing = (
    <BrowserRouter>
        <Switch>
            <Route exact path="/" component={Home}/>
            <Route exact path="/activate" component={Activate}/>
            <Route exact path="/activateDone" component={ActivateDone}/>
            <Route exact path="/billing" component={InvoiceList} />
            <Route exact path="/accounts" component={Accounts}/>
            <Route exact path="/accounts/newAccount" component={NewAccount}/>
            <Route exact path="/accounts/:id/view" component={ViewAccount}/>
            <Route exact path="/accounts/:id/edit" component={EditAccount}/>
            <Route exact path="/confirm" component={Confirm}/>
            <Route exact path="/confirmDone" component={ConfirmDone}/>
            <Route exact path="/password/done" component={PasswordDone}/>
            <Route exact path="/password/expired" component={PasswordExpired}/>
            <Route exact path="/password/forgot" component={PasswordForgot}/>
            <Route exact path="/password/reset" component={PasswordReset}/>
            <Route exact path="/password/sent" component={PasswordSent}/>
            <Route exact path="/settings" component={Settings}/>
            <Route exact path="/users" component={Users}/>
            <Route exact path="/users/addUser" component={UserAdd}/>
            <Route exact path="/users/:id/edit" component={UserEdit}/>
            <Route exact path="/billing/invoices" component={InvoiceList} />
            <Route exact path="/signIn" component={SignIn}/>
            <Route exact path="/signOut" component={SignOut}/>
            <Route component={NotFound}/>
        </Switch>
    </BrowserRouter>
)

ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
