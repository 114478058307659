import React from 'react'
import {Col, Row,Container} from "react-bootstrap";
import Link from "../../component/Link/Link";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import Layout from "../../component/Layout/Layout";
import './notFound.scss';

const Notfound = () =>
<>
    <Layout>
        <Container className="container-404 text-center">
            <Row>
                <Col>
                    <img src="/image/robot.png" width="280" alt="404 robot"/>
                </Col>
                <Col>
                    <h1>
                        404
                    </h1>
                    <p>Uh-oh! Page not found</p>
                    <Link href="/" className="primary btn btn-lg" faIcon={faHome} label="Back to home" ></Link>
                </Col>
            </Row>
        </Container>
    </Layout>
</>

export default Notfound
