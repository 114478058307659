import React from 'react';
import {get, post} from '../../utils/request';
import {Col, Form, Row} from 'react-bootstrap';
import Alert from "../../component/Alert/Alert";
import Container from "../../component/Container/Container";
import Layout from "../../component/Layout/Layout";
import Link from "../../component/Link/Link";
import {faArrowCircleLeft, faCogs, faUser, faWindowClose} from "@fortawesome/free-solid-svg-icons";
import {CountryByCode} from "../../component/CountryByCode";
import CloseAccount from "./CloseAccount";
import Badge from "../../component/Badge/Badge";

const filesize = require('filesize');

class ViewAccount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            data: {},
            error: '',
        };
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        const [res, err] = await get(this, "/partner/auth/account/" + this.state.id);
        if (err) {
            if (err.response && err.response.status && err.response.status === 404) {
                this.setState({errorMessage: "Data not available at this moment"});
            } else {
                this.setState({errorMessage: "error: " + err});
            }
            return
        }
        this.setState({data: res.data});
    }

    updateClosed = () => {
        this.loadData();
    }

    render() {
        const { data, error } = this.state;
        if (!this.state.data.id) {
            return <div>Loading data...</div>
        }

        let statusBadge = "";
        if(data.status === "waiting_activation") {
            statusBadge = <Badge className="badge-warning-filled" label="waiting for activation"/>;
        } else if (data.status === "active") {
            statusBadge = <Badge className="badge-success-filled" label="active"/>;
        } else if (data.status === "waiting_confirm") {
            statusBadge = <Badge className="badge-warning-filled" label="waiting for confirmation"/>;
        } else if (data.status === "closed") {
            statusBadge = <Badge className="badge-danger-filled" label="closed"/>;
        }

        return <Layout activeMenu="/accounts" isLoggedIn={true}>
            <h1 className="main-heading">View account</h1>
            {error && <Alert variant="danger">{error}</Alert>}
            <Container>
                    <h2>Account details</h2>
                    <Form.Group as={Row} controlId="formEmail">
                        <Form.Label column sm="2">Email</Form.Label>
                        <Col sm="10">
                            <Form.Text>{data.email}</Form.Text>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formName">
                        <Form.Label column sm="2">Name</Form.Label>
                        <Col sm="10">
                            <Form.Text>{data.name}</Form.Text>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formStatus">
                        <Form.Label column sm="2">Status</Form.Label>
                        <Col sm="10">
                            <Form.Text>{statusBadge}</Form.Text>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formExternalID">
                        <Form.Label column sm="2">External ID</Form.Label>
                        <Col sm="10">
                            <Form.Text>{data.externalId}</Form.Text>
                        </Col>
                    </Form.Group>
            </Container>
            <Container>
                <h2>Billing details</h2>
                <Form.Group as={Row} controlId="formCountry">
                    <Form.Label column sm="2">Country</Form.Label>
                    <Col sm="10">
                        <Form.Text>{data.countryCode ? CountryByCode(data.countryCode) : ""}</Form.Text>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formEmailForBilling">
                    <Form.Label column sm="2">Email for billing</Form.Label>
                    <Col sm="10">
                        <Form.Text>{data.emailForBilling}</Form.Text>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formCompany">
                    <Form.Label column sm="2">Company</Form.Label>
                    <Col sm="10">
                        <Form.Text>{data.company}</Form.Text>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formAddress">
                    <Form.Label column sm="2">Address</Form.Label>
                    <Col sm="10">
                        <Form.Text>{data.address}</Form.Text>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formRegistrationNumber">
                    <Form.Label column sm="2">Registration number</Form.Label>
                    <Col sm="10">
                        <Form.Text>{data.registrationNumber}</Form.Text>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formVatNumber">
                    <Form.Label column sm="2">VAT number</Form.Label>
                    <Col sm="10">
                        <Form.Text>{data.vatNumber}</Form.Text>
                    </Col>
                </Form.Group>
            </Container>
            <Container>
                <h2>Usage limit</h2>
                <Form.Group as={Row} controlId="formUsageLimit">
                    <Form.Label column sm="2">Usage limit</Form.Label>
                    <Col sm="10">
                        <Form.Text>{filesize(data.usageLimit)}</Form.Text>
                    </Col>
                </Form.Group>
            </Container>
            <div className="d-flex justify-content-end">
                <Link className="primary-outline btn btn-md mr3" faIcon={faArrowCircleLeft} href={"/accounts"} label="Back"/>
                {data.status !== "closed" && <Link className="primary btn btn-md mr3" faIcon={faCogs} href={"/accounts/" + data.id + "/edit"} label="Edit account"/>}
                {data.status !== "closed" && <CloseAccount accountId={data.id} updateClosed={this.updateClosed} />}
            </div>
        </Layout>;
    }
}

export default ViewAccount