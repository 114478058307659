import React from 'react';
import { get } from '../../utils/request';
import "../../component/Table/Table.scss"
import {Spinner} from "react-bootstrap";
import Alert from "../../component/Alert/Alert";
import Link from "../../component/Link/Link";
import {faCogs, faUser} from "@fortawesome/free-solid-svg-icons";
import Badge from "../../component/Badge/Badge";
import Layout from "../../component/Layout/Layout";
import SkipIfNotAdminUser from "../../component/SkipIfNotAdminUser/SkipIfNotAdminUser";

class Users extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            roles: [],
            errorMessage: "",
            loading: true,
            loadingSuccess: false,
            successMessage: "",
        };
        this.loadData = this.loadData.bind(this);
        this.loadRoles = this.loadRoles.bind(this);
    }

    async loadRoles() {
        const [res, err] = await get(this, "/partner/auth/role");
        if (err) {
            this.setState({errorMessage: "error: " + err});
            this.setState({loading: false});
            return;
        }
        this.setState({roles: res.data});
        this.setState({loading: false});   
        this.setState({loadingSuccess: true});
    }

    async loadData() {
        if(this.state.roles.editUsers === false) {

            return;
        }
        const [res, err] = await get(this, "/partner/auth/user");
        if (err) {
            this.setState({errorMessage: "error: " + err});
            this.setState({loading: false});
            return;
        }
        this.setState({data: res.data});
        this.setState({loading: false});
        this.setState({loadingSuccess: true});
    }

    async componentDidMount() {
        await this.loadRoles();
        await this.loadData();
    }

    render() {
        const { data, errorMessage, loading, loadingSuccess } = this.state;

        return <Layout activeMenu="/users" isLoggedIn={true}>
            <SkipIfNotAdminUser>
            {loading &&
                    <>
                        <Spinner animation="border" variant="primary"/> Loading...
                        <br/><br/>
                    </>
                }
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            {loadingSuccess &&
                    <>
                        <h1>Users</h1>
                        <table className="table-ctr">
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Status</th>
                                <th>Can edit users</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {data && data.map((value, index) => {
                                let statusBadge = "";

                                if(value.status === "disabled") {
                                    statusBadge = <Badge className="badge-danger-filled" label="disabled"/>;
                                } else if (value.status === "active") {
                                    statusBadge = <Badge className="badge-success-filled" label="active"/>;
                                } else if (value.status === "waiting_confirm") {
                                    statusBadge = <Badge className="badge-warning-filled" label="waiting for confirmation"/>;
                                }

                                let editUsers = <span className="badge badge-pill badge-warning">No</span>;
                                if (value.rolesEditUsers === true) {
                                    editUsers = <span className="badge badge-pill badge-success">Yes</span>;
                                }

                                return <tr key={index}>
                                    <td data-label={"Name"}>{value.name}</td>
                                    <td data-label={"Email"}>{value.email}</td>
                                    <td data-label={"Status"}>{statusBadge}</td>
                                    <td data-label={"Can edit users"}>{editUsers}</td>
                                    <td><Link className="btn primary-outline" faIcon={faCogs} href={"/users/" + value.partnerUserId + "/edit"} label="Edit user"/></td>
                                </tr>
                            })}
                            </tbody>
                    </table>
                    <div className="d-flex">
                        <Link className="primary btn btn-md mr3 btn-margin btn-right" faIcon={faUser} label="Add
                        user" href={"/users/addUser"}/>
                    </div>
                </>
            }
            </SkipIfNotAdminUser>
        </Layout>
    }
}

export default Users