import React from 'react'
import {post} from '../../utils/request'

class SignOut extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        const [res, err] = await post(this, "/partner/signOut", {});
        if (err) {
            this.setState({message: "error: " + err})
            return
        }
        this.props.history.push('/signIn')
    }

    render() {
        return <div>
            <h1>Signing out ...</h1>
        </div>;
    }
}

export default SignOut