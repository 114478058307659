import React from 'react'
import Alert from "../../component/Alert/Alert";
import Badge from "../../component/Badge/Badge";
import Link from "../../component/Link/Link";
import {get, getHost} from '../../utils/request'
import Layout from "../../component/Layout/Layout";
import {Spinner} from "react-bootstrap";
import "../../component/Table/Table.scss"
import {faFileArrowDown} from "@fortawesome/free-solid-svg-icons";

const dayjs = require('dayjs');

const dateFormat = 'DD.MM.YYYY';

class InvoiceList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            invoices: [],
            errorMessage: "",
            loading: true,
            loadingSuccess: false,
        };
        this.loadData = this.loadData.bind(this);
    }

    async loadData() {
        const [res, err] = await get(this, "/partner/auth/invoices");
        if (err) {
            this.setState({errorMessage: "error: " + err});
            this.setState({loading: false});
            return
        }
        this.setState({invoices: res.data});
        this.setState({loading: false});
        this.setState({loadingSuccess: true});
    }

    async componentDidMount() {
        await this.loadData({});
    }

    render() {
        const { errorMessage, invoices, loading, loadingSuccess, successMessage } = this.state;

        return <Layout activeMenu="/billing/invoices" isLoggedIn={true}>
            <h1>Billing</h1>
            <h2>Invoices ({invoices ? invoices.length : 0})</h2>
            {loading &&
            <>
                <Spinner animation="border" variant="primary"/> Loading invoices...
                <br/><br/>
            </>
            }
            {successMessage && <Alert variant="success">{successMessage}</Alert>}
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            {loadingSuccess &&
            <>
                {invoices &&
                    <>
                         <table className="table-ctr">
                        <thead>
                            <tr>
                                <th>Invoice Date</th>
                                <th>Sum</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                        {invoices && invoices.map((value, index) => {
                            return <tr key={index}>
                                <td data-label="Invoice Date">{dayjs(value.InvoiceDate).format(dateFormat)}</td>
                                <td data-label="Sum">{value.TotalSum} EUR</td>
                                <td data-label="Status">
                                    {value.Paid && <Badge className="badge-success-outline" label="Paid"/>}
                                    {!value.Paid && <Badge className="badge-danger-outline" label="Not paid"/>}
                                </td>
                                <td>
                                    <Link className="lnk-btn primary-outline"
                                          faIcon={faFileArrowDown}
                                            href={getHost() + "/partner/auth/invoice/" + value.Id + "/pdf"}
                                            label="Download"
                                            target="_tab"/>
                                </td>
                            </tr>
                        })}
                        </tbody>
                    </table>
                    </>
                }
            </>
            }
        </Layout>;
    }
}

export default InvoiceList
