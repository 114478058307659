import React from 'react'
import {Nav} from 'react-bootstrap'
import "./SideMenu.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCog,
    faHouseUser,
    faAddressCard,
    faUserGroup,
    faMoneyCheckDollar,
} from "@fortawesome/free-solid-svg-icons";

const SideMenu = ({activeMenu}) => {

    return (
        <section>
            <Nav activeKey={activeMenu}>
                <Nav.Link href="/"><FontAwesomeIcon className="icon" icon={faHouseUser}/><span>Home</span></Nav.Link>
                <Nav.Link href="/accounts"><FontAwesomeIcon className="icon" icon={faAddressCard} /><span>Accounts</span></Nav.Link>
                <Nav.Link href="/users"><FontAwesomeIcon className="icon" icon={faUserGroup} /><span>Users</span></Nav.Link>
                <Nav.Link href="/settings"><FontAwesomeIcon className="icon" icon={faCog} /><span>Settings</span></Nav.Link>
                <Nav.Link href="/billing/invoices"><FontAwesomeIcon className="icon" icon={faMoneyCheckDollar} /><span>Billing</span></Nav.Link>
            </Nav>
        </section>
    );
}

export default SideMenu;