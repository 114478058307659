import React from 'react';
import {get, post, put} from '../../utils/request';
import {Col, Form, FormLabel, Row} from 'react-bootstrap';
import Alert from "../../component/Alert/Alert";
import Button from "../../component/Button/Button";
import Container from "../../component/Container/Container";
import Layout from "../../component/Layout/Layout";
import Link from "../../component/Link/Link";
import {faArrowCircleLeft, faUser} from "@fortawesome/free-solid-svg-icons";
import CountrySelector from "../../component/CountrySelector";
import {CountryByCode} from "../../component/CountryByCode";
import DropdownSelector from "../../component/DropdownSelector";
import {humanize, unHumanize} from "../../utils/humanReadableSize";

const filesize = require('filesize');

const statuses = [
    {label: "", value: ""},
    {label: "Waiting Confirmation", value: "waiting_confirmation"},
    {label: "Waiting Activation", value: "waiting_activation"},
    {label: "Active", value: "active"}
];

class EditAccount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            billingEmailError: false,
            id: this.props.match.params.id,
            usage: 0,
            usageLimitInput: "",
            data: {
                usageLimit: "",
            },
            errors: {},
            errorMessage: '',
            loadingSuccess: false,
            successMessage: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleCountryCodeChange = this.handleCountryCodeChange.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.hasError = this.hasError.bind(this)
        this.showError = this.showError.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this);
        this.showBillingEmailError = this.showBillingEmailError.bind(this);
        this.validateBillingEmail = this.validateBillingEmail.bind(this);
        this.handleChangeUsageLimit = this.handleChangeUsageLimit.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    capitalize(string) {
        return string ? string[0].toUpperCase() + string.slice(1) : "";
    }

    async loadData() {
        const [res, err] = await get(this, "/partner/auth/account/" + this.state.id);
        if (err) {
            this.setState({errorMessage: "error: " + err});
            this.setState({loading: false});
            this.setState({errors: {}})
            return ''
        }

        const data = res.data;

        this.setState({data: data});
        this.setState({usageLimitInput: humanize(data.usageLimit)});
        this.setState({usage: data.usage});

        this.setState({data: res.data});
        this.setState({loading: false});
        this.setState({loadingSuccess: true});
    }

    async handleChangeUsageLimit(event) {
        const value = event.target.value;
        const data = this.state.data;
        data.usageLimit = unHumanize(value);
        this.setState({data: data});
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const data = this.state.data;
        data[name] = value;

        this.setState({data: data});
    }


    handleCountryCodeChange(newValue) {
        const data = this.state.data;
        data.countryCode = newValue;
        this.setState({data: data});
    }

    handleStatusChange(value) {
        const data = this.state.data;
        data.status = value;
        this.setState({data: data});
    }

    hasError(field) {
        if (this.state.errors[field]) {
            return true
        }
        return false
    }

    showError(field) {
        const error = this.state.errors[field]
        if (error) {
            this.errorTranslator(error, field)
            return error.message;
        }
        return
    }

    errorTranslator(error, field) {
        if (error.key === "length") {
            switch (field) {
                case "name":
                    error.message = "Please enter a " + field.toLowerCase();
                    break;
            }

        } else if (error.key === "email") {
            error.message = "Please enter your " + field.toLowerCase() + " in format: yourname@example.com";
        }
    }

    validateBillingEmail(billingEmail) {
        let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');

        return regex.test(billingEmail);
    }

    checkErrorsSubmit(value) {
        if (!this.validateBillingEmail(value)) {

            return true;
        }
    }

    showBillingEmailError(value) {
        if (value) {
            return "Please enter your billing email in format: yourname@example.com";
        }
        return "";
    }

    async handleSubmit(event) {
        event.preventDefault();

        if (this.state.data.emailForBilling) {
            const error = this.checkErrorsSubmit(this.state.data.emailForBilling);
            if (error) {
                this.setState({billingEmailError: true})

                return;
            }
            this.setState({billingEmailError: false})
        }

        const data = this.state.data;
        data.usageLimit = data.usageLimit.toString();

        const [res, err] = await put(this, "/partner/auth/account/" + this.state.id, data);

        if (err) {
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 409:
                        this.setState({error: "Email already exists."});
                        return;
                    case 422:
                        const errors = err.response.data.ValidationErrors
                        this.setState({errors: errors})
                        return;
                }
            }
            this.setState({error: "error: " + err});

            return;
        }
        this.props.history.push('/accounts')
    }

    render() {
        const {billingEmailError, data, error} = this.state;
        if (!this.state.data.id) {
            return <div>Loading data...</div>
        }

        return <Layout activeMenu="/accounts" isLoggedIn={true}>
            <h1 className="main-heading">Edit account</h1>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form onSubmit={e => this.handleSubmit(e)}>
                <Container>
                    <h2>Account details</h2>
                    <Form.Group as={Row} controlId="formEmail">
                        <Form.Label column sm="2">Email*</Form.Label>
                        <Col sm="10">
                            <Form.Control isInvalid={this.hasError("email")}
                                          type="email"
                                          name="email"
                                          onChange={this.handleChange}
                                          value={data.email}
                            />
                            <Form.Control.Feedback
                                type="invalid"
                                className="invalid-feedback">{this.showError("email")}</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formName">
                        <Form.Label column sm="2">Name*</Form.Label>
                        <Col sm="10">
                            <Form.Control isInvalid={this.hasError("name")}
                                          type="text"
                                          name="name"
                                          onChange={this.handleChange}
                                          value={data.name}
                            />
                            <Form.Control.Feedback
                                type="invalid"
                                className="invalid-feedback">{this.showError("name")}</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formStatus">
                        <Form.Label column sm="2">Status*</Form.Label>
                        <Col sm="10">
                            <DropdownSelector
                                name="status"
                                data={statuses}
                                defaultValue={{
                                    value: data.status,
                                    label: this.capitalize(data.status)
                                }}
                                onChange={this.handleStatusChange}
                                hasError={this.hasError("status")}
                            />
                            {this.hasError("status") &&
                                <div className="invalid-feedback block-display form-validation-error">Please select a
                                    status</div>
                            }
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formExternalId">
                        <Form.Label column sm="2">External ID</Form.Label>
                        <Col sm="10">
                            <Form.Control type="text"
                                          name="externalId"
                                          onChange={this.handleChange}
                                          value={data.externalId}
                            />
                            <Form.Control.Feedback
                                type="invalid"
                                className="invalid-feedback">{this.showError("externalId")}</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                </Container>
                <Container>
                    <h2>Billing details</h2>
                    <Form.Group as={Row} controlId="formEmailForBilling">
                        <Form.Label column sm="2">Email for billing</Form.Label>
                        <Col sm="10">
                            <Form.Control isInvalid={billingEmailError}
                                          type="email"
                                          name="emailForBilling"
                                          onChange={this.handleChange}
                                          value={data.emailForBilling}
                            />
                            <Form.Control.Feedback
                                type="invalid"
                                className="invalid-feedback">{this.showBillingEmailError(billingEmailError)}</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formCountryCode">
                        <Form.Label column sm="2">Country</Form.Label>
                        <Col>
                            <CountrySelector
                                defaultValue={data.countryCode ? {
                                    value: data.countryCode,
                                    label: CountryByCode(data.countryCode)
                                } : {
                                    value: "",
                                    label: ""
                                }}
                                onChange={this.handleCountryCodeChange.bind(this)}
                                hasError={this.hasError("countryCode")}
                            />
                            {this.hasError("countryCode") &&
                                <div className="invalid-feedback block-display form-validation-error">Please select a
                                    country</div>
                            }
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formCompany">
                        <Form.Label column sm="2">Company</Form.Label>
                        <Col sm="10">
                            <Form.Control isInvalid={this.hasError("company")}
                                          type="text"
                                          name="company"
                                          onChange={this.handleChange}
                                          value={data.company}
                            />
                            <Form.Control.Feedback
                                type="invalid"
                                className="invalid-feedback">{this.showError("company")}</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formAddress">
                        <Form.Label column sm="2">Address</Form.Label>
                        <Col sm="10">
                            <Form.Control
                                type="text"
                                name="address"
                                defaultValue={data.address}
                                isInvalid={this.hasError("address")}
                                onChange={this.handleChange}
                            />
                            <Form.Control.Feedback
                                type="invalid">{this.showError("address")}</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formRegistrationNumber">
                        <Form.Label column sm="2">Registration number</Form.Label>
                        <Col sm="10">
                            <Form.Control
                                type="text"
                                name="registrationNumber"
                                defaultValue={data.registrationNumber}
                                isInvalid={this.hasError("registrationNumber")}
                                onChange={this.handleChange}
                            />
                            <Form.Control.Feedback
                                type="invalid">{this.showError("registrationNumber")}</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formVatNumber">
                        <Form.Label column sm="2">VAT number</Form.Label>
                        <Col sm="10">
                            <Form.Control
                                type="text"
                                name="vatNumber"
                                defaultValue={data.vatNumber}
                                isInvalid={this.hasError("vatNumber")}
                                onChange={this.handleChange}
                            />
                            <Form.Control.Feedback
                                type="invalid">{this.showError("vatNumber")}</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                </Container>
                <Container>
                    <h2>Storage</h2>
                    <Form.Group as={Row} controlId="formUsageLimit">
                        <Form.Label column sm="2">Usage limit</Form.Label>
                        <Col sm="10">
                            <Form.Control type="text" name="usageLimitInput" defaultValue={this.state.usageLimitInput}
                                          onChange={this.handleChangeUsageLimit}/>
                            <Form.Control.Feedback
                                type="invalid">{this.showError("usageLimit")}</Form.Control.Feedback>
                        </Col>
                        <FormLabel>
                            <br/>
                            Current usage: <b>{filesize(data.usage)}</b>
                            <br/>
                            Current limit: <b>{humanize(data.usageLimit)}</b>
                            , used <b>{Math.round(data.usage / data.usageLimit * 100)}%</b>
                        </FormLabel>
                    </Form.Group>
                </Container>
            </Form>
            <div className="d-flex justify-content-end">
                <Link className="primary-outline btn btn-md mr3" faIcon={faArrowCircleLeft} href={"/accounts"}
                      label="Back"/>
                <Button className="btn primary" faIcon={faUser} label="Save account" onClick={this.handleSubmit}/>
            </div>
        </Layout>;
    }
}

export default EditAccount