import React from 'react';
import queryString from 'query-string';
import {post} from '../../utils/request';
import Alert from "../../component/Alert/Alert";
import Layout from "../../component/Layout/Layout";
import Link from "../../component/Link/Link";

class Confirm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmError: false,
        };
    }

    async componentDidMount() {
        const values = queryString.parse(this.props.location.search);
        if (!values.h) {
            this.setState({confirmError: true});

            return;
        }

        const data = {hash: values.h};

        const [res, err] = await post(this, "/partner/emailConfirm", data);
        if (err) {
            this.setState({confirmError: true});

            return;
        }

        this.props.history.push('/confirmDone');
    }

    render() {
        const { confirmError } = this.state;

        return <Layout activeMenu="">
            <div className="password-ctr">
                <h1>Confirm email</h1>
                {confirmError
                    ? <Alert variant="danger">
                        Received error when confirming.
                        <br/>It could be that the email has already been confirmed
                        <br/>and you can <Link className="lnk" href="/signIn" label="Sign in"/>
                        <br/>
                        <br/>If you need any help, please <Link className="lnk" target="_blank" href="https://storadera.com/contact" label="Contact us"/>
                    </Alert>
                    : <Alert variant="info">Please wait, confirming ...</Alert>
                }
            </div>
        </Layout>;
    }
}

export default Confirm