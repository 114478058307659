import React from 'react';
import { get } from '../../utils/request';
import Alert from "../../component/Alert/Alert";
import Layout from "../../component/Layout/Layout";
import {Spinner} from "react-bootstrap";
import './home.scss';

class Home extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            errorMessage: "",
            data: [],
            roles: [],
            loading: true,
            loadingSuccess: false,
        }
        this.loadData = this.loadData.bind(this);
    }

    async loadData() {
        const [res, err] = await get(this, "/partner/auth/settings");
        if (err) {
            this.setState({errorMessage: "error: " + err});
            this.setState({errors: {}})
            this.setState({loading: false});

            return;
        }
        this.setState({data: res.data});

        this.setState({loading: false});
        this.setState({loadingSuccess: true});
    }

    componentDidMount() {
        this.loadData();
    }

    render() {
        const { errorMessage, loading, loadingSuccess } = this.state;

        return <Layout activeMenu="/" isLoggedIn={true}>
            <>
                {loading &&
                    <>
                        <Spinner animation="border" variant="primary"/> Loading...
                        <br/><br/>
                    </>
                }
                {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                {loadingSuccess &&
                    <>
                    <h1>Welcome</h1>
                    </>
                }
            </>
        </Layout>;
    }
}

export default Home



