import React from 'react';
import {post} from '../../utils/request';
import {Col, Form, Row} from 'react-bootstrap';
import Alert from "../../component/Alert/Alert";
import Button from "../../component/Button/Button";
import Container from "../../component/Container/Container";
import Layout from "../../component/Layout/Layout";
import Link from "../../component/Link/Link";
import {faArrowCircleLeft, faUser} from "@fortawesome/free-solid-svg-icons";

class NewAccount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                name: '',
                email: '',
                company: '',
                externalId: '',
            },
            errors: {},
            error: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.hasError = this.hasError.bind(this)
        this.showError = this.showError.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const data = this.state.data;
        data[name] = value;

        this.setState({data: data});
    }

    hasError(field) {
        if (this.state.errors[field]) {
            return true
        }
        return false
    }
    showError(field) {
        const error = this.state.errors[field]
        if (error) {
            this.errorTranslator(error, field)
            return error.message;
        }
        return
    }

    errorTranslator(error, field) {
        if (error.key === "length") {
            switch (field) {
                case "name":
                    error.message = "Please enter a " + field.toLowerCase();
                    break;
            }

        } else if (error.key === "email") {
            error.message = "Please enter your " + field.toLowerCase() + " in format: yourname@example.com";
        }
    }
    async handleSubmit(event) {
        event.preventDefault();

        const [res, err] = await post(this, "/partner/auth/account", this.state.data);

        if (err) {
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 409:
                        this.setState({error: "Email already exists."});
                        return;
                    case 422:
                        const errors = err.response.data.ValidationErrors
                        this.setState({errors: errors})
                        return;
                }
            }
            this.setState({error: "error: " + err});

            return;
        }
        this.props.history.push('/accounts')
    }

    render() {
        const { data, error } = this.state;

        return <Layout activeMenu="/accounts" isLoggedIn={true}>
                <h1 className="main-heading">Create new account</h1>
            {error && <Alert variant="danger">{error}</Alert>}
            <Container>
            <Form onSubmit={e => this.handleSubmit(e)}>
                <h2>Account details</h2>
                <Form.Group as={Row} controlId="formEmail">
                    <Form.Label column sm="2">Email</Form.Label>
                    <Col sm="10">
                        <Form.Control       isInvalid={this.hasError("email")}
                                            type="text"
                                            name="email"
                                            onChange={this.handleChange}
                                            value={data.email}
                        />
                        <Form.Control.Feedback
                            type="invalid"
                            className="invalid-feedback">{this.showError("email")}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formName">
                    <Form.Label column sm="2">Name</Form.Label>
                    <Col sm="10">
                        <Form.Control   isInvalid={this.hasError("name")}
                                        type="text"
                                        name="name"
                                        onChange={this.handleChange}
                                        value={data.name}
                        />
                        <Form.Control.Feedback
                            type="invalid"
                            className="invalid-feedback">{this.showError("name")}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formCompany">
                    <Form.Label column sm="2">Company</Form.Label>
                    <Col sm="10">
                        <Form.Control   isInvalid={this.hasError("company")}
                                        type="text"
                                        name="company"
                                        onChange={this.handleChange}
                                        value={data.company}
                        />
                        <Form.Control.Feedback
                            type="invalid"
                            className="invalid-feedback">{this.showError("company")}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formExternalID">
                    <Form.Label column sm="2">External ID</Form.Label>
                    <Col sm="10">
                        <Form.Control type="text"
                                      isInvalid={this.hasError("externalId")}
                                      name="externalId"
                                      onChange={this.handleChange}
                                      value={data.externalId}
                        />
                        <Form.Control.Feedback
                            type="invalid"
                            className="invalid-feedback">{this.showError("externalId")}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
            </Form>
        </Container>
        <div className="d-flex justify-content-end">
            <Link className="primary-outline btn btn-md mr3" faIcon={faArrowCircleLeft} href={"/accounts"} label="Back"/>
            <Button className="btn primary" faIcon={faUser} label="Create account" onClick={this.handleSubmit}/>
        </div>
        </Layout>;
    }
}

export default NewAccount