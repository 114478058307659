import React from 'react'
import { post } from '../../utils/request';
import Alert from "../../component/Alert/Alert";
import Button from "../../component/Button/Button";
import Container from "../../component/Container/Container";
import Link from "../../component/Link/Link";
import { Col, Form, Row } from "react-bootstrap";
import Layout from "../../component/Layout/Layout";
import queryString from 'query-string'
import './signIn.scss';

class SignIn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                user: '',
                pass: '',
            },
            disableLoginFields: false, // This is to prevent password managers to autofill password during rerender after first password submit attempt (for example when mfa modal opens)
            errors: {},
            message: '',
            modal: false,
            warning: '',
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.hasError = this.hasError.bind(this);
        this.showError = this.showError.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const data = this.state.data;
        data[name] = value;

        this.setState({data: data});
    }

    hasError(field) {
        if (this.state.errors[field]) {
            return true;
        }
        return false;
    }

    showError(field) {
        const error = this.state.errors[field];
        if (error) {
            return error.message;
        }
    }

    async handleSubmit(event) {
        if (event) { // TODO - remove if possible
            event.preventDefault();
        }

        const [res, err] = await post(this, "/partner/signIn", this.state.data);
        if (err) {
                if (err.response && err.response.status) {
                    switch (err.response.status) {
                        case 404:
                            this.setState({message: "Failed to log in. Either email or password is not correct."});

                            return;
                        case 412:
                            switch (err.response.data.StatusText) {
                                case "waiting_confirm":
                                    this.setState({warning: "Please confirm your email before logging in."});
                                    return;
                                case "waiting_activation":
                                    this.setState({warning: "Your account is waiting in queue to be activated."});

                                    return;
                            }
                            this.setState({warning: "account not active"});

                            return;
                        case 422:
                            const errors = err.response.data.ValidationErrors;
                            this.setState({errors: errors});
                            this.setState({message: "Not able to log in"});

                            return;
                    }

                }
                this.setState({message: "error: " + err});

                return;
            }
            this.setState({message: "Signed in"});

            let redirectTo = "/";

            const search = this.props.location.search;
            const values = queryString.parse(search)
            if (values.rt) {
                redirectTo = "/" + values.rt;
            }

            this.props.history.push(redirectTo);
    }

    render() {
        const { disableLoginFields, message, warning } = this.state;


        let signUpLink = `${process.env.REACT_APP_HOMEPAGE_URL}`;

        if (`${process.env.NODE_ENV}` !== "development") {
            signUpLink += "/partner-sign-up"
        }

        return <Layout layoutClass="signin-ctr__bg " activeMenu="/signIn">
            <img src="../../../image/Storadera_BG_light.png" height="100%" alt="Storadera_Background"/>
            <div>
                <div>
                <h1>Sign in</h1>
                    <Form onSubmit={this.handleSubmit}>
                        <Container variant="borderless">
                            {warning && <Alert variant="warning">{warning}</Alert>}
                            {message && <Alert variant="danger">{message}</Alert>}
                            <Form.Group as={Row} controlId="formPlaintextUser">
                                <Form.Label column sm="2">Email</Form.Label>
                                <Col sm="10">
                                    <Form.Control type="text" name="user" onChange={this.handleChange}
                                                  isInvalid={this.hasError("User")} disabled={disableLoginFields}/>
                                    <Form.Control.Feedback type="invalid">{this.showError("User")}</Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formBasicPassword">
                                <Form.Label column sm="2">Password</Form.Label>
                                <Col sm="10">
                                    <Form.Control type="password" name="pass" onChange={this.handleChange} disabled={disableLoginFields}
                                                  isInvalid={this.hasError("Pass")}/>
                                    <Form.Control.Feedback type="invalid">{this.showError("Pass")}</Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                    </Container>
                    <Button className="primary btn btn-xl mb4" label="Sign in" type={"submit"}/>
                    <div className="d-flex signin-ctr__btns">
                        <span><p>Don't have an account?</p><Link className="lnk" label="Sign up" href={signUpLink}/></span>
                        <Link className="lnk" label="Forgot password?" href="/password/forgot"/>
                    </div>
                    </Form>
                </div>
            </div>
        </Layout>;
    }
}

export default SignIn